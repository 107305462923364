<template>
  <div
    :class="`AuthScreen CorporateBG ${ [routeClass] }`"
    :style="{ 'background-image': 'url(\''+ backgroundLogo +'\')' }">
    <div class="AuthScreen__Company" :style="{ 'background-image': 'url(\''+ theme.loginImageUrl +'\')' , 'align-items' : getLogoPosition() }">
      <div class="AuthScreen__Company__logo" :style="{ 'background-image': 'url(\'' + theme.logoUrl +'\')' }">
      </div>
    </div>
    <div class="AuthScreen__Content">
      <div class="AuthScreen__Content__header">
        <div style="display:none">
          <a>{{$t('need_help')}}</a>
          <i class="fa fa-ellipsis-v"></i>
        </div>
      </div>

      <router-view />

      <div class="AuthScreen__Content__footer">
        <img class="logo" src="@/assets/img/logo.png" v-if="!theme.hideLogo">
      </div>
    </div>
  </div>
</template>

<script>
  import Logo           from '@/assets/img/logo.svg';
  import { mapGetters } from 'vuex';

  export default {
    name: 'AuthView',

    data ( ) {
      return {
        backgroundLogo  : Logo,
      }
    },

    computed: {
      ...mapGetters( [ 'theme' ] ),

      routeClass ( ) {
        return `Panel--${ this.$route.name.replace( '.', '-' ) }`;
      },
    },
    methods:{
      getLogoPosition(){
        return this.theme.loginLogoPosition ? this.theme.loginLogoPosition.toLowerCase() : "CENTER"
      },
    }

  }
</script>

<style scoped lang="scss">
  .AuthScreen {
    display                   : flex;
    opacity                   : 0;
    animation                 : 1s ease-in 0s forwards fadeIn;
    height                    : 100%;
    background-attachment     : fixed;
    background-repeat         : no-repeat;
    background-position       : center;

    @media only screen and (max-width: 769px) {
      flex-direction          : column;
      width                   : 100%;
    }

    &__Company {
      width                   : 40%;
      display                 : flex;
      justify-content         : center;
      align-items             : center;
      background-repeat       : no-repeat;
      background-size         : cover;

      @media only screen and (max-width: 769px) {
        width                 : 100%;
      }

      &__logo {
        height                : 200px;
        width                 : 200px;
        background-position   : center center;
        background-repeat     : no-repeat;
        background-size       : contain;
      }
    }

    &__Content {
      background              : white;
      display                 : flex;
      flex-direction          : column;
      align-items             : flex-end;
      justify-content         : space-around;
      width                   : 60%;

      @media only screen and (max-width: 769px) {
        width                 : 100%;
      }

      &__header {
        display               : flex;
        justify-content       : flex-end;
        padding               : 10px 15px 0 0;

        div {
          display             : flex;
          align-items         : center;

          a {
            color             : #494949;
          }

          i {
            font-size         : 20px;
            margin            : 10px;
            color             : black;
          }
        }
      }

      &__footer {
        display               : flex;
        justify-content       : flex-end;
        align-items           : center;
        padding               : 1rem 15px 10px 0;

        img.logo {
          width               : 220px;
          height              : 100px;
          @media only screen and ( max-width: 769px ) {
            width             : 110px;
            height            : 50px;
          }
        }

      }
    }

    @keyframes fadeIn {
      100% {
        opacity               : 1;
      }
    }
  }
</style>
